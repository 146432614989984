
import { mapGetters } from 'vuex'
export default {
  head() {
    return {
      title: 'Login • Pen The Journey',
      link: [
        {
          rel: 'canonical',
          href: 'https://penthejourney.com' + this.$route.path
        }
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
  },
  data() {
    return {
      user: {
        email: '',
        pass: '',
        c_pass: '',
        name: '',
      },
      isSigningIn: false,
      isSigningOut: false,
      isSigningUp: false,
    }
  },
  methods: {
    async login() {
      this.isSigningIn = true
      try {
        const { email, pass } = this.user
        await this.$fire.auth.signInWithEmailAndPassword(email, pass)
      } catch (error) {
        console.error(error)
        this.$notify({
          title: 'Error signing in :(',
          text: error,
          type: 'error',
        })
      }
      this.isSigningIn = false
    },
    async signUp() {
      this.isSigningUp = true
      try {
        const { email, c_pass, name } = this.user

        const createdUser =
          await this.$fire.auth.createUserWithEmailAndPassword(email, c_pass) //user logged in here
        const { uid } = createdUser.user;
        const dateCreated = this.$fireModule.firestore.FieldValue.serverTimestamp();
        await this.$fire.firestore.doc(`users/${uid}`).set({ email, name, dateCreated })
      } catch (error) {
        console.error(error)
        this.$notify({
          title: 'Error creating account',
          text: error,
          type: 'error',
        })
      }
      this.isSigningUp = false
    },
    async logout() {
      this.isSigningOut = true
      try {
        await this.$fire.auth.signOut()
      } catch (error) {
        console.error(error)
      }
      this.isSigningOut = false
    },
  },
}
