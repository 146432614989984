import { render, staticRenderFns } from "./login.vue?vue&type=template&id=84d4a9f8"
import script from "./login.vue?vue&type=script&lang=js"
export * from "./login.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthLogin: require('/opt/build/repo/components/Auth/Login.vue').default})
