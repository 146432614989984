
import { mdiEye, mdiEyeOff } from '@mdi/js'
export default {
  props: {
    user: Object,
    isSigningUp: Boolean,
    isSigningIn: Boolean,
  },
  data() {
    return {
      mdiEye,
      mdiEyeOff,
      showPass: true,
      reveal: true,
      isPassResetEmailSending: false,
      passResetDialog: false,
    }
  },
  computed: {
    nameRules() {
      const rules = []
      let rule
      rule = (v) => !!v || 'Name is required'
      rules.push(rule)
      //rule = v => isNaN(v) || "Only numbers are allowed";
      return rules
    },
    emailRules() {
      const rules = []
      let rule
      rule = (v) => !!v || 'Email is required'
      rules.push(rule)
      //rule = v => isNaN(v) || "Only numbers are allowed";
      return rules
    },
    passwordRules() {
      const rules = []
      let rule
      rule = (v) => !!v || 'Password is required'
      rules.push(rule)
      //rule = v => isNaN(v) || "Only numbers are allowed";
      return rules
    },
    confirmPasswordRules() {
      const p = this.user.pass
      const rules = []
      let rule
      rule = (v) => !!v || 'Password confirmation is required'
      rules.push(rule)

      rule = (v) => v === p || 'Passwords do not match'
      rules.push(rule)
      //rule = v => isNaN(v) || "Only numbers are allowed";

      return rules
    },
  },
  methods: {
    signUp() {
      //TODO: Form validation
      if (!this.$refs.form.validate()) return
      this.$emit('signUp')
    },
    login() {
      this.$emit('login')
    },
    async sendPasswordResetEmail() {
      try {
        this.isPassResetEmailSending = true
        await this.$fire.auth.sendPasswordResetEmail(this.user.email)
        this.$notify({ title: 'Email sent!', type: 'success' })
        this.isPassResetEmailSending = true
        this.passResetDialog = false
      } catch (error) {
        console.error(error)
        this.$notify({ title: error, type: 'error' })
        this.isPassResetEmailSending = false
        this.passResetDialog = false
      }
    },
  },
}
